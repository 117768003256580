@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto+Flex:opsz,wght@8..144,100..1000&family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&family=Great+Vibes&family=Satisfy&display=swap');
:root {
  --main-color: #026A90;
  --light-gradient: linear-gradient(to right, #00AEEF, #A6E7FF, #4ECFFF, #A6E7FF, #00AEEF);
  --dark-gradient: linear-gradient(to right, #026A90, #4ECFFF, #026A90, #026A90)
}

body,
html {
  width: 100%;
  overflow-x: hidden;
  font-family: "Open Sans", sans-serif;
  color: #333333;
}

p,
li {
  font-size: 17px;
}
.lazyload-wrapper {
  display: inline !important;
}
img.img-fluid {
  width: 100%;
}

a:hover {
  color: #d1c05e !important;
}

.header .d-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .d-flex>div {
  width: 18%;
}

.logo img {
  width: 300px;
  margin: auto;
  display: block;
}

.header p {
  margin-bottom: 3px;
  font-size: 16px;
}

.logo {
  width: 25% !important;
}

.header {
  width: 100%;
  left: 0; 
  top: 0;
  z-index: 99;
  color: #666666;
  background: #F5F5F5;
  padding: 10px 0;
  position: fixed;
  top: 0;
}

.login-link {
  position: absolute;
  z-index: 9;
  right: 10%;
}

.birthday-content h6 {
  font-size: 16px;
  color: #bf1adf;
  margin-bottom: 15px;
  font-weight: 600;
}

.login-link img {
  width: 140px;
  transform: rotate(90deg);
  margin-top: 22px;
}

.header p a:hover {
  color: #d1c05e !important
}

.header .admission-btn button {
  border: 2px solid #026a90;
  border-radius: 6px;
  padding: 15px 41px;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: 0.5s;
  margin-left: 32px;
  display: block;
  letter-spacing: 1px;
  color: #026A90;
  font-weight: 600;
}

.header .admission-btn button:hover,
.life-our-school .content-blk a:hover,
.tpr-block .d-flex .button button:hover,
.news-events .title .button:hover,
.message-desk li.slide button:hover,
.blue_btn:hover {
  background: #026a9073;
  color: #fff !important
}

.menu-btn {
  width: 10% !important;
  padding-right: 15px;
}

.imp-link {
  width: 25% !important;
  text-align: right;
}

.menu-toggle img {
  width: 43px;
  margin-left: auto;
  display: block;
}

.imp-link p {
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.imp-link p a {
  margin: 0 10px;
}

.header-contact img {
  width: 20px;
  margin-right: 6px;
}


.header-contact div {
  text-align: left;
  margin: auto;
  display: block;
  width: max-content;
}

.slider-main .content {
  bottom: 18%;
  left: 5%;
  position: absolute;
  z-index: 1;
  padding: 0 20px;
  border-left: 3px solid #026A90;
  z-index: 99;
}

.slider-main .item:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #00000030;
  z-index: 9;
}

.slider-main {
  min-height: 100vh;
  position: relative;
  margin-top: 110px;
}

.slider-main .content h1 {
  width: max-content;
  max-width: 100%;
  border-radius: 15px; 
  text-transform: uppercase;
  font-weight: 700;
  font-size: 55px;
  margin: 0;
  text-shadow: 0px 0px 5px #4a4a4a;
}

.slider-main .content .h1-bg { 
  color: #fff; 
  border-radius: 15px;
  margin-bottom: 17px;
  width: max-content;
}

.slider-main .content p {
  background: #00000099;
  color: #fff;
  padding: 17px 22px;
  width: 580px;
  max-width: 100%;
  font-size: 17px;
  border-radius: 15px;
  line-height: 30px;
}

.slider-main .content i {
  background: #666666;
  color: #fff;
  padding: 5px 20px;
  font-size: 18px;
  border-radius: 22px;
  border: 0.5px solid #18b4ee;
  margin-top: 0px;
  display: inline-block;
}
.main-slider iframe{
  width: 100%;
  height: 793px
} 
.owl-item.active {
  position: relative;
}

.highlights img {
  width: 35px;
}

.highlights {
  text-align: center;
  margin-top: -10px;
  z-index: 9;
  position: relative;
  background: var(--main-color);
}

.highlights .col-lg-2:before {
  background: radial-gradient(#fff, #0000);
  content: "";
  height: 100%;
  left: 100%;
  position: absolute;
  top: 0;
  width: 2px;
  z-index: 9;
}

.highlights .col-lg-10 {
  background: var(--main-color);
}

.highlights .col-lg-10 marquee p {
  display: inline-block;
  color: #fff;
  padding: 5px;
  font-size: 15px;
  margin-bottom: 0;
  padding-right: 10px;
}

.highlights .col-lg-2 {
  color: #fff;
  font-size: 17px;
  font-weight: 800;
  letter-spacing: 1px;
  position: relative;
}

p.social-media {
  position: fixed;
  right: 0;
  z-index: 99;
  top: 50%;
  transform: translate(-0%, -50%);
}

p.social-media a {
  background: radial-gradient(#ffffff94, white);
  display: block;
  text-align: center;
  padding: 9px;
  margin: 8px 0;
  border-radius: 7px;
  transition: 0.5s;
  margin-right: 0;
  position: relative;
  box-shadow: 0px 0px 5px 0px #0000;
}

p.social-media a:hover {
  background: #d1c05e; 
  box-shadow: 0px 0px 5px 0px #424242;
}

.title_main span {
  background: var(--dark-gradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 700;
  position: relative;
}

.academic-cal .active {
  display: flex;
}

.academic-cal>div {
  display: none;
}

h2.title_main span {
  font-size: 10rem;
}

h2.title_main {
  font-size: 42px;
  position: relative;
  width: max-content;
  max-width: 100%;
  margin-bottom: 40px;
  margin-top: 0;
  font-weight: 600;
}

.about {
  padding: 80px 0 0;
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/bg-shape.png) no-repeat;
  background-size: 100%;
  background-position: center;
}

.abt-cont-blk h2.title_main {
  color: #666666;
}

.about>div {
  width: 78%;
  margin: auto
}

.abt-cont-blk {
  width: 80%;
}

.abt-cont-blk p {
  font-size: 18px;
  text-align: justify;
}

.journey {
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/journey-bg.png);
  background-size: 100% 100%;
  margin-bottom: 80px;
  min-height: 500px;
}

.journey p {
  font-size: 17px;
}

.journey img.right-img {
  width: 36%;
  float: right;
  margin-left: 40px;
}

.journey>div {
  width: 87%;
  margin: auto;
  padding: 50px 0 30px;
  min-height: 680px;
}


.journey .tabs {
  position: relative;
  width: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 70px;
  align-items: flex-end;
  min-height: 112px;
  padding-left: 100px;
  padding-right: 30px;
}

.journey .tabs:before {
  content: "";
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0;
  border: 1px solid #b18434;
}

.journey .tab-content {
  padding: 0 40px;
  transition: 0.5s;
}

.journey .tab-content .active {
  animation: 2s fadeing 0s alternate;
  text-align: justify;
}

@keyframes fadeing {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.life-our-school>div {
  width: 66%;
  margin: auto;
}

.journey .tabs button:after {
  width: 16px;
  content: "";
  height: 16px;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 27%);
  left: 50%;
  background: radial-gradient(#4ecfff, #026a90);
  border-radius: 50%;
  border: 0px solid #6370cf00;
  transition: 0.5s;
}

.life-our-school h2.title_main.m-auto {
  margin-bottom: 80px !important;
}

.journey .tabs button {
  position: relative;
  background: unset;
  border: unset;
  min-height: 60px;
  font-size: 24px;
  font-weight: 600;
  transition: 0.5s;

  color: #999999;
}

.journey .tabs button.active,
.journey .tabs button:hover {
  font-size: 40px;
  min-height: 112px;
  font-weight: 600;
  color: #000
}

.journey .tabs button.active::after,
.journey .tabs button:hover::after {
  border: 2px solid #fff;
  width: 41px;
  height: 41px;
  bottom: -4px;
  background: radial-gradient(#4ecfff, #026a90);

}

.journey .tabs button:before {
  width: 16px;
  content: "";
  height: 16px;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 27%);
  left: 50%;
  background: #b1843500;
  border-radius: 50%;
  border: 2px dashed #bb9147;
  transition: 0.5s;
  box-shadow: inset 0px 0px 0px 4px #ffffff, inset 0px 0px 0px 8px #b18434;
}

.journey .tabs button.active:before,
.journey .tabs button.active:hover:before {
  width: 60px;
  content: "";
  height: 60px;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 41%);
  left: 50%;
  background: #b1843500;
  border-radius: 50%;
  border: 2px dashed #bb9147;
  transition: 0.5s;
  animation: 20s rotate infinite alternate;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, 41%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, 41%) rotate(360deg);
  }
}

h2.title_main.m-auto {
  margin: auto;
}

h2.title_main.m-auto small {
  display: block;
  line-height: 7px;
  font-size: 26px;
  color: #333;
}

.life-our-school .row>div {
  padding: 0;
}

.life-our-school {
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/life-school.png);
  background-size: 100% 100%;
}

.life-our-school .row>div .d-flex {
  display: flex;
}

.life-our-school img {
  width: 100%;
  height: 300px;
}

.life-our-school .content-blk {
  height: 300px;
  background: var(--main-color);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  text-align: center;
  color: #fff;
  box-shadow: 2px 0px 5px 0px #000;
}

.col-sm-3 .arrow-bottom {
  box-shadow: 0px 2px 5px 0px #000;
}

.life-our-school .arrow-left {
  box-shadow: -2px 0px 5px 0px #000;
}

.life-our-school .arrow-top {
  box-shadow: 0px -2px 5px 0px #000;
}

.life-our-school .row>div .d-flex>div {
  width: 100%;
}

.life-our-school .content-blk h3 {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
}

.life-our-school .virtual img {
  height: auto !important;
  width: 90px;
  margin: auto; 
  display: block;
}

.virtual {
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  text-align: center;
  color: var(--main-color);
  background: #fff;
}

.life-our-school .content-blk a {
  font-size: 16px;
  width: max-content;
  background: #ffffff38;
  padding: 8px 22px;
  border-radius: 9px;
  border: 1px solid #fff;
  letter-spacing: 1px;
  font-weight: 600;
  transition: 0.5s
}

.life-our-school .content-blk:before {
  content: "";
  position: absolute;
  border-bottom: 20px solid var(--main-color);
  border-left: 25px solid #0000;
  border-right: 25px solid #0000;
}

.life-our-school .arrow-top:before {
  top: -20px;
}

.life-our-school .content-blk {
  position: relative;
}

.content-blk:before {
  content: "";
  position: absolute;
  border-bottom: 20px solid var(--main-color);
  border-left: 25px solid #0000;
  border-right: 25px solid #0000;
}

.arrow-top:before {
  top: -20px;
}

.arrow-right:before {
  right: -30px;
  transform: rotate(90deg);
  bottom: 19%;
}

.arrow-bottom:before {
  right: 14%; 
  border-top: 20px solid var(--main-color);
  border-bottom: unset !important;
  bottom: -17px;
}

.content-blk {
  position: relative;
}

.arrow-left:before {
  left: -30px;
  transform: rotate(90deg);
  border-top: 20px solid var(--main-color);
  border-bottom: unset !important;
  bottom: 14%;
}

.topper {
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/topper-bg.png);
  color: #fff;
  padding: 80px 40px;
  background-size: 100% 100%;
}

.topper-theme .item {
  height: 270px;
}


.topper-cont {
  text-align: center;
}

.topper-cont p {
  margin: 0;
}

.topper-img {
  width: 220px;
  margin: auto;
  margin-bottom: 0px;
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/topper-shape.png);
  transition: 0.5s;
  padding: 23px 43px 46px;
  background-size: 100% 100%;
  margin-top: 0;
}

.owl-item.active+.owl-item .topper-img {
  width: 172px;
  padding: 23px 38px 40px;
  height: 150px;
  margin-top: 30px;
}

.owl-item.active:nth-child(1) .topper-img {
  width: 60%;
}


.topper-theme .owl-stage-outer {
  padding-bottom: 40px;
}

.topper-theme .owl-nav.disabled,
.gallery .owl-nav.disabled {
  display: block !important;
  position: absolute;
  left: 0;
  bottom: 0px;
}

.topper-theme .owl-nav.disabled button,
.gallery .owl-nav.disabled button {
  background: #0000 !important;
  width: 53px;
  font-size: 30px !important;
  border: 1px solid #fff !important;
  line-height: 26px !important;
  border-radius: 9px !important;
  font-weight: 400;

  padding-bottom: 6px !important;
}


.title {
  min-height: 150px;
}

.date {
  float: right;
}

.evt-title {
  background: linear-gradient(90deg, #BF953F, #E0D670, #B38728, #D8CE6B, #AA771C);
  color: #000;
  padding: 6px 10px;
  border-radius: 10px;
  margin-top: 11px;
  margin-bottom: 13px;
}

.evt-content p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.evt-title h3 {
  margin: 0;
  font-size: 17px;
  text-align: center;
}

p.category {
  position: absolute;
  right: 10px;
  bottom: 0;
  padding: 3px 10px;
  background: #026a90bd;
  color: #fff;
  border-radius: 7px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.evt-img {
  position: relative;
}

.evt-img img {
  width: 100%;
  height: 260px;
  object-fit: contain;
  background: #ddd;
}

.gal-img img {
  height: 240px;
  object-fit: contain;
  background: #ededed;
}

.imageModal {
  display: flex;
  justify-content: flex-end;
}

.imageModal>div {
  display: flex;
}

.imageModal {
  display: flex;
  justify-content: flex-end;
}

.imageModal>div {
  display: flex;
}

div#slideshowAnim {
  background: #000000db !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.evt-blk {
  padding: 20px;
  box-shadow: 0px 5px 4px 0px #dddddd8c;
  width: 94%;
  margin: 40px auto;
  border-radius: 15px;
  background: #fff;
  position: relative;
}

.evt-blk:before {
  content: "";
  width: 100%;
  height: 106%;
  position: absolute;
  left: 0;
  top: 0;
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/shadow-bg.png);
  background-size: 100% 100%;
  z-index: -1;
}

.evt-dtl {
  font-size: 14px;
  margin: 20px 0;
}

.academic-cal>div {
  display: none;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;
  background: #026a903b;
  margin-bottom: 10px;
  border-radius: 12px;
  padding: 20px;
}

.academic-cal>div p {
  margin-bottom: 0;
  min-width: 60px;
}

.academic-cal {
  height: 480px;
  overflow-y: scroll;
  background: #fff;
  padding: 26px;
  margin: 50px 0;
  border-bottom: 20px solid #fff;
  box-shadow: 0px 9px 5px 0px #0000002e;
  border-radius: 15px;
}

.academic-cal::-webkit-scrollbar {
  width: 10px;
  background: #e1e1e1;
}

.academic-cal::-webkit-scrollbar-thumb {
  width: 20px;
  background: linear-gradient(90deg, #BF953F, #E0D670, #B38728, #D8CE6B, #AA771C);
  border-radius: 10px
}

.message-desk li.slide button,
.blue_btn {
  background: #026a90;
  color: #026A90;
  border: 1px solid #026A90;
  padding: 7px 18px;
  border-radius: 5px;
  margin-left: auto;
  display: block;
  margin-top: 25px;
  color: #ffffff;
}

.blue_btn {
  margin: auto;
}

.message-desk ul.control-dots {
  display: none;
}

.message-desk .navigation-thumbs {
  width: 60%;
  margin: auto;
  margin-top: -110px;
}

.message-desk .carousel.carousel-slider .control-arrow {
  background: #026a9073;
  color: #fff !important;
}

.message-desk .carousel.carousel-slider .control-arrow:before {
  border-left-color: #fff !important;
  border-right-color: #fff !important
}

.message-desk .navigation-thumbs .owl-nav.disabled {
  display: block;
  position: absolute;
  right: -160px;
  top: 40%;
}

.message-desk .navigation-thumbs .owl-nav.disabled button {
  width: 54px;
  font-size: 35px;
  line-height: 26px;
  border: 1px solid var(--main-color);
  margin: 2px 6px;
  border-radius: 11px;
  display: inline-flex;
  color: var(--main-color);
  align-items: center;
  justify-content: center;
}

.message-desk .navigation-thumbs .owl-item.active:nth-child(2) {
  transform: scale(1);
}

.message-desk .navigation-thumbs .owl-item.active:nth-child(2) .message-des {
  display: block
}

.message-desk .navigation-thumbs .owl-item.active {
  transform: scale(0.5);
}

.message-desk .navigation-thumbs .owl-item.active .message-des {
  display: none;
}

.message-desk {
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/message-bg.png);
  background-size: 100% 100%;
  padding: 130px 0;
  background-position: center;
  background-attachment: fixed;
  padding-top: 240px;
}

.message-desk .row {
  display: flex;
  align-items: center;
}

p.carousel-status {
  opacity: 0;
}

.message-desk .thumbnails img {
  width: 140px;
  margin: 10px;
  box-shadow: 0px 0px 5px 0px #838383;
  border: 1px solid #9b9b9b;
  border-radius: 16px;
  transition: 0.5s;
  background: #fff;
}

.message-desk .thumbnails img.active {
  width: 220px;
}

.message-desk .thumbnail-carousel-container {
  width: 80%;
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/testibg.png);
  padding: 30px;
  border-radius: 15px;
  margin: auto;
  background-size: 100% 100%;
}

.message-desk .thumbnail-carousel-container:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%)
}

.message-desk .thumbnail-carousel-container .thumbnails {
  margin-top: -160px;
  min-height: 260px;
  position: relative;
  z-index: 99;
}

.message-desk h3 {
  margin-bottom: 0;
}

.message-title {
  width: max-content;
  margin: auto;
  position: relative;
  max-width: 100%;
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/message-bg1.png);
  color: #fff;
  padding: 29px 10px;
  border-radius: 17px;
  background-size: 100% 100%;
}

.message-title h2.title_main.m-auto small {
  color: #fff;
}


.message-desk>div {
  width: 90%;
  margin: auto;
}

.message-slider {
  background: #dfe7e88a;
  padding: 20px 40px;
  border-radius: 15px;
  box-shadow: 1px 6px 5px 0px #ddd;
  margin: 40px 0;
  margin-top: 200px;
  width: 90%;
  border: 1px solid #c09d46;
  margin-left: auto;
}

.message-slider .owl-item a {
  margin-left: auto;
  display: block;
  background: var(--main-color);
  width: max-content;
  padding: 7px 20px;
  color: #fff !important;
  border-radius: 5px;
}

.message-des h3 {
  font-size: 18px;
}

.message-des {
  text-align: center;
  margin-bottom: 30px;
}

.news-events {
  padding: 80px 0;
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/news.png);
  background-size: 100% 100%;
  position: relative;
  background-attachment: fixed;
}

.evt-block .button {
  padding: 11px 39px !important;
}


.news-events>div {
  width: 80%;
  margin: auto
}

.evt-block {
  width: 90%;
}

.news-events:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: radial-gradient(#ffffffb3, white);
  z-index: 9;
}

.news-events>div {
  position: relative;
  z-index: 9;
}

.news-events .title {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.news-events .title h2.title_main {
  margin-left: 0;
  margin-bottom: 0;
}

.news-events .title .button {
  min-width: max-content;
  max-width: 100%;
  color: #fff;
  display: flex;
  background: var(--main-color);
  padding: 9px 5px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  margin-right: 12px;
}

.news-events .title .button img {
  margin: 0 10px;
}

.gal-img .description {
  position: absolute;
  width: 100%;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 9;
  bottom: 0;
  flex-direction: column;
  padding: 20px;
  opacity: 0;
  transition: 0.5s;
  background: #000000cf;
  transform: rotate(310deg);
}

.gal-img:hover {
  transform: scale(1.3);
  z-index: 999;
  position: relative;
  display: block;
}

.gal-img .description button {
  padding: 5px 10px 3px;
  background: var(--main-color);
  color: #fff;
  border-radius: 9px;
  border: 1px solid #fff;
  font-size: 13px;

}

.gal-img:hover .description {
  opacity: 1;
  height: 100%;
  transform: rotate(360deg);
}

.gal-img {
  position: relative;
  transition: 0.5s;
  margin: 40px 0;
  width: 89%;
  overflow: hidden;
  border-radius: 9px;
  box-shadow: -1px 4px 5px 0px #0000003d;
}

.gal-img .description h3 {
  margin: 0;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
}

.gallery {
  padding: 100px 0;
  position: relative;
}

.gallery:before {
  content: "";
  width: 19%;
  height: 400px;
  position: absolute;
  left: 0;
  top: 0;
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/gallery-left.png) no-repeat;
  background-size: 100% auto;
}

.gallery:after {
  content: "";
  width: 33%;
  height: 400px;
  position: absolute;
  right: 0;
  top: 0;
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/gallery-right.png) no-repeat;
  background-size: 100% auto;
}



.gallery .owl-nav.disabled {
  display: block !important;
  left: 50%;
  bottom: -50px;
  transform: translate(-50%, 0%);
}

.gallery .owl-nav.disabled button {
  color: #fff !important;
  background: #026a901a !important;
  border: 1px solid var(--main-color) !important;
  color: var(--main-color) !important;
}

.ofc-link li {
  display: flex;
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 200;
}

.ofc-link li span {
  min-width: 130px;
  max-width: 130px;
  font-weight: 600;
  margin-right: 13px;
}

.footer {
  width: 90%;
  margin: auto;
}

.quick-link ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 180px;
}

.quick-link ul li {
  font-size: 15px;
  margin-bottom: 10px;
  list-style: none;
  text-transform: uppercase;
  font-weight: 200;
}

.cont-dtl p {
  font-size: 15px;
  display: flex;
  font-weight: 200;
}

.cont-dtl p i {
  font-size: 17px;
  margin-right: 10px;
}

footer iframe {
  height: 220px;
}

.quick-link ul {
  padding: 0;
}

.cont-dtl h3 {
  margin: 0;
  margin-bottom: 14px;
  text-transform: uppercase;
}

footer {
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/footer-bg.png);
  position: relative;
  background-size: 100% 100%;
  color: #fff;
  padding: 30px 0 0;
}


.ofc-link {
  padding: 20px;
  background: #026a904a;
  border-radius: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.ofc-link ul {
  padding: 0;
}

.footer .logo {
  margin-bottom: 30px;
  width: 300px !important;
}

.quick-link {
  position: relative;
}

.quick-link:after {
  width: 1px;
  height: 100%;
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background: radial-gradient(white, transparent);
}

.button-list {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
  background: var(--main-color);
  margin-top: 20px;
  padding: 5px 0;
}

.button-list a {
  padding: 6px 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #fff;
  text-transform: uppercase;
  min-width: 100px;
  text-align: center;
}

.button-list a.result {
  background: #bb2525;
  border: 0
}


.copyright {
  position: relative;
  background: #0000;
  text-align: center;
  padding: 5px;
  font-size: 13px;
  color: #ffffffc9;
}

.socialMedia ul {
  padding: 0;
  display: flex;
  margin-left: 10px;
  margin-bottom: 0;
}

.socialMedia ul li {
  list-style: none;
  margin: 5px;
  background: var(--main-color);
  padding: 5px 8px;
  border-radius: 50%;
  border: 1px solid;
}

.socialMedia {
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialMedia p {
  font-size: 20px;
  margin-bottom: 0;
  letter-spacing: 3px;
}

.message-title img.quote {
  position: absolute;
  right: 20px;
  top: 20px;
}

.life-our-school {
  padding: 80px 0px;
}

.news-event-filter {
  position: absolute !important;
  transform: translate(-0%, -50%);
  left: 0;
  top: 50%;
  width: max-content !important;
}

.news-event-filter>div {
  background: #fff;
  color: #000;
  margin: 10px 0;
  padding: 15px 10px;
  font-size: 16px;
  border-radius: 0;
  text-align: center;
  min-width: 140px;
  text-transform: uppercase;
}

.news-event-filter>div.active {
  background: var(--main-color);
  color: #fff;
}


.journey .tabs::-webkit-scrollbar,
.menu::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.carousel-indicators .carousel-indicator {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
}

.carousel-indicators .carousel-indicator.active {
  opacity: 1;
}

.thumbnails {
  width: max-content;
  margin: auto;
  max-width: 100%;
}

a:hover {
  color: #d1c05e !important
}

.carousel.carousel-slider .control-arrow {
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0px;
  background: #ffffff00;
  left: unset;
  height: max-content;
  line-height: 22px;
  width: 48px;
  border-radius: 10px;
  color: #000 !important;
}

.carousel.carousel-slider {
  overflow-y: visible;
}

button.control-arrow.control-prev {
  right: 60px !important;
}

.carousel.carousel-slider .control-arrow.control-prev:before {
  border-right: 8px solid #000;
}

.carousel.carousel-slider .control-arrow.control-next:before {
  border-left: 8px solid #000;
}

.tpr-block .d-flex {
  display: flex;
  align-items: center;
}

.tpr-block .d-flex .button button {
  width: max-content;
  padding: 8px 20px;
  background: #026A90;
  margin: auto;
  display: block;
  border: 0px;
  font-size: 17px;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid;
  transition: 0.5s;
}

.tpr-block .d-flex .button {
  width: 20%;
}

.tpr-block .d-flex .owl-carousel {
  width: 87%;
}

.message-title {
  width: 100%;
}

.gallery h2.title_main {
  text-align: center;
}

.message-title h2.title_main span {
  background: linear-gradient(to right, #BF953F, #E0D670, #B38728, #D8CE6B, #AA771C);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.tpr-block.animateMe h3 {
  padding-left: 55px;
}

.topper-theme .owl-nav.disabled {
  left: 60px;
}

.topper-img img {
  width: 100%;
  background: #ecefe8 !important;
  border-radius: 50%;
}

.message-desk .carousel.carousel-slider button.control-arrow.control-prev {
  padding-left: 0;
}

.message-desk .carousel.carousel-slider button.control-arrow.control-next {
  padding-right: 0;
}

/* Fest Animation */

.message-box img {
  width: 50px;
}

.fest button {
  padding: 5px 20px;
  font-size: 16px;
} 
 
.fest-bg {
  background: linear-gradient(178deg, #026a90,#023142);
  padding: 80px 0px;
}
.fest-bg .button {
  display: block;
  margin: auto;
  width: max-content;
  max-width: 100%;
}

.fest-bg .button button {
  text-transform: uppercase;
  padding: 9px 20px;
  background: var(--main-color);
  border: 1px solid #fff;
  color: #fff;
  letter-spacing: 1px;
  border-radius: 5px;
}
.fest-bg .message-title {
  background: transparent;
  padding: 0;
  margin-bottom: 0;
}
.fest button{
  margin-top: 40px;
}
.manor-_blk{
  width: 95% !important;
}
.manor-_blk img{
  width: 100%;
}
.fest button.slick-arrow{
  display: none !important;
}
 
/* SCROLL DOWN */
 

/* Fest Animation */
.birthday-block {
  padding: 30px 0;
  background-position: center;
  background: #D0C8D5;
  box-shadow: inset 0px 0px 17px 0px #00000069;
  border-radius: 15px;
  position: relative;
}

.remove-cont .owl-nav.disabled {
  display: none !important;
}

img.gift-right {
  width: 80px;
  margin-left: auto;
  display: block;
  margin-right: 30px;
}

.birthday {
  width: 90%;
  margin: auto;
  margin-top: 50px;
}

.birthday-block marquee {
  width: 34%;
  margin: auto;
  display: block;
  min-height: 180px;
  padding-left: 20px;
}

.contentBirthday p {
  font-size: 14px;
  margin-bottom: 0;
}

.birthday-block .upcoming img {
  width: 40px;
}

.birthday-block marquee .birthday-blk {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.birthday-block .upcoming {
  margin-bottom: 0;
  margin-top: 30px;
}

.birthday-block marquee .birthday-blk img {
  width: 60px;
  margin-right: 20px;
  border: 2px solid #026a90;
  border-radius: 50%;
}

.birthday-content img {
  width: 90px !important;
  margin: auto;
  border: 2px solid #026a90;
  border-radius: 50%;
  margin-bottom: 20px;
  opacity: 0.7;
}


.birthday-content {
  text-align: center;
  width: 89%;
  margin: auto
}

.birthday-block:before {
  content: "";
  width: 50%;
  height: 300px;
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/birthday-icon.png);
  position: absolute;
  left: -90px;
  top: -90px;
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
}

.upcoming img {
  width: 50px;
}

.upcoming {
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.title.message-box {
  margin-bottom: 40px;
}

.birthday-theme .owl-nav.disabled {
  display: block;
  font-size: 3rem;
}

.birthday-theme .owl-nav.disabled button {
  width: 69px;
  text-align: center;
  line-height: 32px; 
  border: 1px solid #444444;
  border-radius: 21px;
  padding: 0px 0 2px 0 !important;
}

.main-birthday {
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/main-birthday-bg.png);
  background-position: center;
  background-size: 100% 100%;
  padding-bottom: 70px;
}

.slider-main .item img {
  height: 87vh;
}

video#videoPlayer {
  height: 87vh;
  object-fit: cover;
  object-position: center;

}

/* Inner Page */
.breadcrumb {
  background: var(--main-color);
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/sld-2.jpg);
  color: #fff;
  position: relative;
  background-position: top;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-self: center;
  margin-top: 4%;
}

.breadcrumb:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(0deg, black, #0000008c, #00000069);
}

.breadcrumb ul {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
}

.breadcrumb ul li {
  padding: 0 5px;
  font-size: 15px;
  text-transform: uppercase;
}

.breadcrumb h2 {
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
  font-size: 66px;
  opacity: 0.6;
  font-weight: 700;

}

.col-md-12.note {
  background: #f1f1f1;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 2px 20px 11px -20px #5a5a5a;
  margin-top: 20px;
  margin-bottom: 20px;
}

.inner-section p i {
  color: #f02da1;
}

.inner-section p i {
  margin-right: 8px;
}

p.inner-title {
  font-size: 21px;
  color: var(--main-color);
  font-weight: 600;
  margin-top: 18px;
}

h3.inner-main-title {
  color: #f02da1;
  margin-bottom: 60px;
  font-size: 30px;
  font-weight: 800;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 20px;
  text-transform: uppercase;
}

.inner-section p span {
  color: #f02da1;
}

p.inner-title span {
  color: var(--main-color);
}

.inner-section p,
.inner-section li {
  line-height: 30px;
}

.inner-section {
  padding: 30px 0 60px;
  text-align: justify;
}

.flex-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-top: 24px;
  padding-left: 0;
  list-style: none;
}

.flex-list li {
  width: 33.3%;
  /* padding: 5px 10px; */
  margin-bottom: 10px;
  color: var(--main-color);
}

.list-view li {
  position: relative;
  padding-left: 25px;
}

.list-view li:before {

  content: "";
  width: 15px;
  position: absolute;
  left: 0;
  top: 5px;

  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/right-arrow.png) no-repeat;
  height: 30px;
  background-size: 100%;

}

ul.list-view {
  padding: 0;
  list-style: none;
}

.box-light {
  display: flex;
  /* background: #ddd; */
  box-shadow: 20px 20px 15px -23px #000;
  margin-top: 20px;
}

.box-light .heading {
  width: 30%;
  background: linear-gradient(45deg, #004b66, #026a908f);
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  flex-direction: column;
}


.box-light .content {
  width: 70%;
  padding: 20px;
}

.box-light .list-view li {
  margin-bottom: 8px;
}

.d-flex.rules-reg {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  border-left: 5px solid var(--main-color);
  padding: 30px;
  box-shadow: 1px 6px 20px -10px #00000059;
  border-radius: 0px;
}

.d-flex.rules-reg img {
  margin-right: 30px;
}

.d-flex.rules-reg p.inner-title {
  margin-top: 0;
}

.owl-item.active+.owl-item .topper-img img {
  height: 88px;
}

.topper-img img {
  height: 128px;
  object-fit: contain;
  object-position: top;
}

.about-img:before {
  content: "";
  width: 83%;
  height: 69%;
  position: absolute;
  left: 50px;
  top: 20%;
  background: var(--main-color);
  /* border-radius: 50%; */
  border: 5px solid var(--main-color);
  background: transparent;
  border-radius: 15px;
  box-shadow: -7px 12px 35px -20px #000;
}

.about-img .about-img1 {
  width: 86%;
  border-radius: 15px;
  position: relative;
}

.about-img .about-img2 {
  width: 64%;
  margin-left: auto;
  display: block;
  margin-top: -60px;
  border-radius: 15px;
  position: relative;
  box-shadow: -2px 13px 15px -10px #00000070;
}

.principalMessage {
  border: 6px solid #ddd;
  box-shadow: 0px 40px 14px -37px #000000a1;
}


.timeline>.d-flex {
  display: flex;
  align-items: center;
}

.timeline>.d-flex>div {
  width: 40%;
  text-align: right;
  padding: 0 40px;
  /* margin-bottom: 70px; */
}

.timeline>.d-flex>div.content1 {
  text-align: left;
  width: 60%;
}

.timeline:before {
  width: 3px;
  height: 93%;
  content: "";
  background: #026a93;
  position: absolute;
  left: 40%;
  border-radius: 50px;
}

.timeline>.d-flex:before {
  content: "";
  width: 30px;
  height: 30px;
  background: radial-gradient(#ffffff, #f02da1);
  border-radius: 50%;
  border: 2px solid #f02da1;
  left: 40%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.timeline>.d-flex:after {
  content: "";
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  border: 2px dashed #026a93;
  left: 40%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: 12s rotate1 infinite;
}

@keyframes rotate1 {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
    transform-origin: center;
  }

  100% {
    transform: translate(-50%, -50%) rotate(180deg);
    transform-origin: center;
  }
}

.timeline>.d-flex {
  position: relative;
  margin-bottom: 60px;
}

.timeline>.d-flex>div.content1 span {
  font-weight: 700;
}

.timeline>.d-flex>div p.inner-title {
  text-transform: uppercase;
}

.slick-track {
  display: flex;
}

.slick-list {
  overflow: hidden;
}

.evt-block button.slick-arrow {
  display: none !important;
}



.count-val p {
  margin: 0;
  padding: 0px;
}

.no-data:before {
  content: "No Data Found";
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  font-size: 70px;
  top: 50%;
}

.newsCount,
.eventCount,
.galleryCount {
  position: relative;
  background: #fff;
}

.newsCount:before,
.event-blk:before,
.galleryCount:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: #f7f7f7;
  left: 0;
}

.event-blk {
  position: relative;
  margin-bottom: 15px;
  box-shadow: 0px 0px 6px 0 #ddd;
}

/* .next { clear: both; background: #0f4e8c; margin: auto; display: block; color: #fff; padding: 5px 20px; width: max-content; cursor: pointer; }  */

.event-flex {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.inr-event-blk {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 20px 0px rgb(0 0 0 / 25%);
}

.inr-event-img {
  min-width: 250px;
  width: 250px;
  height: auto;
  min-height: 160px;
  margin-right: 5px;
  overflow: hidden;
  will-change: transform;
}

.inr-event-img img {
  width: 250px;
  height: 100%;
  transition: all 1.5s cubic-bezier(0, 0, .2, 1);
  will-change: transfrom;
  object-fit: contain;
  background: #f1f1f1;
  padding-left: 0px;
  max-height: 220px;
}

.inr-event-content {
  overflow: hidden;
  padding: 10px 15px;
  word-break: break-word;
  position: relative;
  width: 100%;
}

.inr-event-content:before {
  display: none;
  position: absolute;
  content: "";
  height: 100%;
  width: 98%;
  background: linear-gradient(45deg, #eddfeb, #fff4da);
  transform: skewX(70deg);
  top: 0;
  left: 0;
}

.inr-event-content h6.date {
  font-size: 13px;
  line-height: 22px;
  color: #ea1f17;
  margin-bottom: 8px !important;
  font-weight: 600;
  position: relative;
  margin: 0;
}

.inr-event-content h3 {
  color: var(--main-color);
  font-size: 16px;
  line-height: 28px;
  display: block;
  margin-bottom: 5px !important;
  font-weight: 600;
  position: relative;
  margin: 0;
  text-transform: uppercase;
}

.inr-event-content p {
  line-height: 28px !important;
  color: #383838 !important;
  padding: 0;
  margin: 0;
  position: relative;
  margin-bottom: 15px !important;
}

.inr-event-content h6.loc {
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 10px !important;
  position: relative;
  margin: 0;
  width: 100%;
}

.inr-event-content h6.loc a {
  color: #6e6e6e;
}

.inr-event-content h6.loc span {
  margin-left: 20px;
  float: right;
}

.inr-event-content h6.loc span i {
  color: #000000;
}

.inr-event-blk:hover .inr-event-img img {
  opacity: 0.8;
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.2, 1.2, 1.2);
}

.inr-news-blk h6.date {
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  margin: 0px 0px;
  color: #ffffff;
  height: 100%;
  background: linear-gradient(to right, #6f7280, #022939);
  padding: 5px 5px;
  width: fit-content;
  letter-spacing: 1px;
}

.inr-news-blk {
  margin-bottom: 20px;
  display: flex;
}

.newsCount {
  box-shadow: 1px 20px 20px -27px rgb(0 0 0 / 25%);
  margin-bottom: 20px;
}

/* .next {clear: both;margin: auto;display: block;color: #fff;padding: 5px 20px;width: max-content;cursor: pointer;background: linear-gradient( to right, #cd1616, #fcb045);margin-top: 30px;}  */

.inr-news-content {
  padding: 10px 15px;
  word-break: break-word;
  position: relative;
  width: 100%;
}

.inr-news-blk h6.date {
  display: block;
  height: unset;
  background: transparent;
  color: var(--main-color);
  font-weight: 600;
}

.inr-news-content h3 {
  color: var(--main-color);
  font-size: 18px;
  line-height: 28px;
  display: block;
  margin-bottom: 5px !important;
  font-weight: 500;
  position: relative;
  margin: 0;
  text-transform: uppercase;
}

.inr-news-content p {
  line-height: 27px !important;
  color: #383838 !important;
  padding: 0;
  margin: 0;
  position: relative;
  margin-bottom: 15px !important;
}

.inr-news-content h6.loc {
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;
  color: #6e6e6e;
  margin-bottom: 8px !important;
  position: relative;
  margin: 0;
}

.inr-news-content h6.loc:hover {
  color: #1daea2;
}

.gal_new {
  padding-bottom: 20px;
}

.block {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.block>div {
  position: relative;
  width: 24%;
  margin-left: 12px;
  margin-bottom: 20px;
}

.block img {
  width: 100%;
  height: 300px;
  object-fit: contain;
  border: 1px solid #bdbdbd;
  padding: 4px;
  transition: 0.5s;
  margin-bottom: 20px;
  background: #ededed;
}

.block img:hover {
  filter: grayscale(100%);
}

.block>div h5 {
  left: 50%;
  font-size: 20px;
  background: #ffdc73;
  padding: 5px 25px;
  position: absolute;
  bottom: 15px;
  transform: translate(-50%, 50%);
  width: max-content;
  color: black;
}

.gal_new article {
  position: relative;
  box-shadow: 0px 0px 9px 0px #b3b3b3;
  border-radius: 5px;
}

.gal_new .total-img {
  position: absolute;
  right: 0;
  top: 0;
  color: #000;
  background: #e1e1e1b5;
}

.gal_new .total-img {
  display: flex;
  padding: 0px 8px;
  border-radius: 10px;
}

.gal_new .total-img p {
  margin-bottom: 0;
  margin-left: 3px;
  background: linear-gradient(to right, #fcb045, #cd1616);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  border-radius: 50%;
  font-size: 12px !important;
  position: absolute;
  left: 0;
  bottom: 2px;
  padding: 0PX;
}

.gal_new .total-img i {
  font-size: 25px;
}

.gal_new .gal-content {
  padding: 15px 15px;
}

h4.catgry-name {
  color: #232323;
  font-size: 17px;
  line-height: 25px;
  border-bottom: 1px dotted #9db4b2;
  margin: 0 !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-transform: uppercase;
  font-weight: 500;
  min-height: 56px;
}

.gal-content p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: black !important;
  margin: 10px 0;
  line-height: 26px !important;
  text-align: left !important;
  height: 3.05em;
}

.gal_new article img {
  width: 100%;
  height: 180px;
  object-fit: contain;
  background: #f1f1f1;
  margin: auto;
  display: block;
  float: inherit;
  padding-left: 0px;
}

.gal_new h6.date {
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;
  color: #6e6e6e;
  margin-bottom: 8px !important;
  position: relative;
  margin-top: 10px;
}

.gal_new h6.date a {
  color: #ffffff;
  font-size: 13px;
}

.gal_new h6.date span {
  margin-left: 20px;
  float: right;
  background: linear-gradient(to right, #6f7280, #022939);
  padding: 0 10px;
}

.gal_new h6.date span i {
  color: #ffffff;
}

.discription h3 {
  text-align: center;
  color: #232323;
  font-size: 19px;
  text-transform: uppercase;
  font-weight: 600;
}

.discription p {
  text-align: center !important;
}

.discription {
  margin-top: 10px;
}

.discription p span {
  font-weight: 600;
  font-size: 18px;
}

.zoompic img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  object-position: center;
  min-height: 200px;
  max-height: 200px;
  margin: 0px auto 0px auto;
  display: block;
  border: 6px double #f440774f;
  padding: 0;
  background: #f1f1f1;
}

h6.date i {
  color: #bd2050;
  margin: 3px;
  font-size: 15px;
  vertical-align: initial;
}

.flexbox-img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.flexbox-img>div {
  width: 23%;
  margin-bottom: 35px;
  margin-left: 20px;
}

.flexbox-img img {
  height: 200px !important;
  object-fit: contain !important;
  object-position: center !important;
  min-height: 200px !important;
  max-height: 200px !important;
  margin: 0px auto 0px auto !important;
  display: block !important;
  border: 6px double #b4dcec !important;
  padding: 0 !important;
  background: #f1f1f1 !important;
  width: 100% !important;
}

.flexbox-v {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.flexbox-v>div {
  width: 23%;
  margin-bottom: 35px;
  margin-left: 20px;
}

.sub-gallery {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1f1f1;
  margin-bottom: 20px;
}

.sub-gallery>div {
  color: #000;
  width: 25%;
  font-size: 16px;
  padding: 10px;
  border-right: 1px solid #bbbbbb;
  text-align: center;
  margin: 0;
  padding: 21px 0;
}

.sub-gallery>div:nth-child(04) {
  border-right: unset;
}

.book-search,
.tabs-dynamic {
  align-items: center;
  display: flex;
}

.fulwidth .discription {
  margin-top: 40px;
  margin-bottom: 30px;
}

.tabs-dynamic {
  background: #ededed;
  background: #f1f1f1;
  border: 1px solid #ccc;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto 40px;
  padding: 10px 0;
  width: 100%;
}

.month-selection select {
  border: 1px solid #ccc;
  margin-left: 10px;
  min-width: 150px;
  padding: 5px 10px;
  width: 95%;
}

.searchBox input {
  background: #fff;
  border: 1px solid #c3c3c3;
  padding: 2px 0 2px 4px;
  width: 100%;
}

.gal_new h6.date {
  float: unset;
}

.topper-service {
  background: #f3f3f3;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 2px 15px 15px -10px #b3b3b3;
  min-height: 320px;
}

.topper-service .thumb img {
  height: 180px;
  width: 100%;
  object-fit: contain;
  background: #e9e9e9;
}


.topper-service .details.clearfix h4 {
  color: var(--main-color);
}

.button-list-block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.button-list-block button {
  background: transparent;
  border: 2px solid var(--main-color);
  padding: 5px 20px;
  margin: 10px;
  border-radius: 5px;
  min-width: 150px;
  font-size: 20px;
  color: var(--main-color);
}

.button-list-block .active button {
  color: #fff;
  background: linear-gradient(173deg, #0080af, #004e6a);
}

#exampleModalToggle.show {
  display: block !important;
  z-index: 99999999;
  opacity: 1;
  background: #0000009e;
}

#exampleModalToggle div#modalH .modal-body {
  padding: 0;
}

#exampleModalToggle .modal-dialog {
  top: 25%;
}

#exampleModalToggle div#modalH h1 {
  margin: 0;
  font-size: 18px;
  width: 100%;
  color: var(--main-color);
}

#exampleModalToggle .modal-header button {
  color: #000;
  border: 0;
  background: transparent;
  font-weight: 800;
  font-size: 20px;
}

#exampleModalToggle .modal-header {
  display: flex;
  justify-content: space-between;
}

#exampleModalToggle div#modalH .modal-body img {
  max-height: 59vh;
  object-fit: contain;
  background: #ddd;
}

#exampleModalToggle .card-body {
  width: 94%;
  margin: auto;
}

#exampleModalToggle .card-body p {
  padding: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

#exampleModalToggle .carousel button.carousel-control-prev,
#exampleModalToggle .carousel button.carousel-control-next {
  display: none;
}

#exampleModalToggle .card.modalcard .d-grid.gap-2.d-md-flex.justify-content-around {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

#exampleModalToggle .card.modalcard .d-grid.gap-2.d-md-flex.justify-content-around.d-block.mx a {
  margin: 10px;
  background: var(--main-color);
  color: #fff !important;
  border: 0;
  font-size: 14px;
  text-transform: uppercase;
}

.ofc-link li a {
  text-transform: none;
}

.widget {
  width: 28%;
  height: 300px;
  overflow: hidden;
}

.facebook-blk {
  width: 280px;
  text-align: center;
  background: #1a78f1;
  padding: 10px 10px 38px;
  color: #fff;
  margin-left: 5%;
}

.facebook-blk p {
  font-size: 14px;
  line-height: 26px;
  width: 83%;
  margin: auto;
}

.facebook-blk:before {
  content: "";
  width: 100%;
  height: 55%;
  background: #1a78f18c;
  position: absolute;
  left: 0;
  top: 60%;
  transform: translate(-0%, -50%);
}

.facebook-blk p {
  position: relative;
}

.facebook {
  padding: 100px 0;
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/facebook-bg.png);
  background-size: 100% 100%;
}

.facebook>div {
  width: 80%;
  margin: auto;
}

.facebook div#fb-root {
  height: 470px;
  overflow: hidden;
  max-width: 100%;
  margin: auto;
  display: block;
  width: 500px;
}
.highlights p span {
  color: #ffdc43;
  text-transform: uppercase;    margin-left: 5px;
}
h3.inner-main-title span {
  background: #026a90;
  color: #fff;
  padding: 12px 10px; 
  width: 100%;
  font-weight: 700;
  letter-spacing: 5px;
}

h3.inner-main-title:before {
  content: "";
  width: 100%;
  height: 98%;
  position: absolute;
  right: 0;
  top: 1px;
  background: #026a90;
  z-index: -1;
}

h3.inner-main-title span.light-bg:before {
  content: "";
  width: 780%;
  height: 100%;
  position: absolute;
  left: 99%;
  top: 0;
  background: #ffffff;
  z-index: -1;
}

h3.inner-main-title span.light-bg {
  background: #fff;
  color: #026a90;
  position: relative;
  padding: 16px 10px;
}

.scroll-up-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.scroll-to-top:hover {
  background-color: #f02da1;
}

.scroll-to-top {
  background-color: var(--main-color);
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 5px #0003;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  transition: opacity .3s,visibility .3s;
  font-size: 25px;
  line-height: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-facility {
  padding: 20px;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 10px;
  box-shadow: 1px 20px 23px -14px #ddd;
  background: #fbfbfb;
  border: 1px solid #dddddd38;
  height: 100%;
  margin-top: 30px;
}
.inner-facility_img {
  width: max-content;
  margin-left: auto;
  background: #f038a5;
  border-radius: 50%;
  padding: 12px;
  box-shadow: 1px 5px 5px 2px #d1d1d1;
}
.inner-facility_img img {
  width: 40px;
  filter: invert(1);
}
.inner-facility p.inner-title {
  margin-top: 0;
  text-transform: uppercase;
}

.school_song {
  width: 65%;
  text-align: center;
  margin: auto;
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/prayer-bg.jpg);
  background-size: 100% 100%;
  padding: 40px 120px;
  position: relative;
  border-radius: 15px;
}

.school_song:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffffba;
}

.school_song img {
  width: 40%;
  position: relative;
  margin-bottom: 20px;
  margin-top: 10px;
}
.school_song p {
  position: relative;
}
.table-responsive table thead {
  background: var(--light-gradient);
}
table {
  width: 100%;
  text-align: center;
  background: #ebebeb;
}
table th {
  color: #000;
  padding: 5px;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid #ffffff3d;
}
table td {
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ddd;
}
.newsletter {
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/e-newsletter.png);
  background-size: 100% 100%;
  position: relative;
}
.newsletter:before{
  content: "";
  width: 100px;
  height: 100px;
  position: absolute;
  left: 0;
  top: 0;
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/email-icon.png);
  background-size: 100%;
  background-repeat: no-repeat;
}
.newsletter:after{
  content: "";
  width: 300px;
  height: 300px;
  position: absolute;
  right: 0;
  top: 0;
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/newsletter-shape.png);
  background-size: 100%;
  background-repeat: no-repeat;
}
.newsletter .card {
  padding: 5px;
  background: #efefef;
  text-align: center;
  text-transform: capitalize;
  border-radius: 5px;
  box-shadow: 0px 38px 12px -30px #ddd;
  border: 1px solid #ddd;
  margin-bottom: 60px;
  width: 95%;
}

.newsletter .card img {
  border: 2px solid #000;
  border-radius: 5px;
  height: auto;
}
.newsletter > div >.row{
  position: relative;
  z-index: 9;
}
.newsletter > div:before{
  content: "";
  width: 360px;
  height: 210px;
  position: absolute;
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/newsletter-gif.gif);
  background-size: 100%;
  background-repeat: no-repeat;
  opacity: 0.2;
  right: -10px;
  bottom: 60px;
}
.newsletter .card h3 {
  font-size: 16px;
  font-weight: 600;
  color: var(--main-color);
  min-height: 48px;
  line-height: 23px;
  margin-bottom: 4px;
}

.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  background: var(--dark-gradient);
}
.marquee img{
  width: 180px;
  margin: 15px 30px;
  filter: grayscale(1);
  cursor: pointer;
}
.educationPartner {
  padding: 50px 0px;   
}  
.educationPartner .d-flex {
  display: flex;
  align-items: center;
}

.educationPartner .d-flex h2 {
  background: #fff;
  padding: 30px;
  /* color: #fff; */
  box-shadow: -3px 20px 15px -3px #00000017;
  text-align: right;
  font-family: "Allura", cursive;
  font-weight: unset;
  color: goldenrod;
  line-height: 35px;
} 
.educationPartner .d-flex h2 i {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  color: #000;
}

.marquee img:hover {
  filter: none;
  transition: 0.5s;
}
.educationPartner .content {
  height: 100%;
  display: flex !important;
  align-items: center;
}

.educationPartner .slick-slide > div {
  height: 100%;
}
.educationPartner button.slick-arrow {
  display: none !important;
}
.educationPartner  h2{

font-family: "Satisfy", cursive;
}
.d-flex.row.ratio .col-lg-3:last-child .counter-blk span {
  display: none;
}
.counter_section{
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/counter-bg.png);
  background-size: 100% 100%;
  background-attachment: fixed;
  position: relative;
  padding: 80px 0;
  background-position: center;
}
.counter_section .row { 
  position: relative;
}

.counter_section .row .counter-blk {
  font-size: 4rem;
  width: 20vh;
  height: 20vh;
  border: 5px solid #a4e7ff;
  margin: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%; 
  transition: 1s;
  font-weight: 800;
  margin: auto;
  margin-bottom: 20px;
}
.counter_section > div {
  width: 90%;
  margin: auto;
}
.counter_section .row > div {
  text-align: center;
  text-transform: uppercase;
  position: relative;
  color: #fff;
  margin-bottom: 30px;
  font-size: 21px;
  letter-spacing: 1px;
  transition: 1s;
}
.counter_section .row .counter-blk:hover {
  box-shadow: 0px 0px 13px 4px #dddddd6b;
}
.counter_section .row > div:hover {
  font-weight: 700;
}
.counter_section:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: radial-gradient(#00000085, #000000a3);
}
.vision {
  background: #dddddd4d;
  padding: 20px;
  text-align: center;
  border: 5px solid #fff;
  box-shadow: 0px 0px 5px 0px #ddd;
}

.vision h3 {
  color: #026a90;
}

.contact-blk > div {
  font-size: 16px;
  display: flex;
  padding: 19px 15px;
  margin-bottom: 30px;
  background: #ffffffd1;
  border-left: 2px solid #f02da1;
  border-radius: 0 5px 5px 0;
  box-shadow: 2px 20px 5px -18px #dddddd94;
  width: 80%;
  align-items: flex-start;
}

.contact-blk > div   img{
  margin-right: 17px; 
}
.contact-frame iframe{
  height: 350px;
  border: 10px solid #fff;
  box-shadow: 0px 0px 5px 0px #ddd;
}

.footerform {
  padding: 20px 30px;
  background: #f7f7f7b8;
  /* border: 8px solid #ffffff; */
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px #cbcbcb;

}
.mb-0{
  margin-bottom: 0;
}
.contact-blk > div img {
  width: 33px;
}
.footerform h3 {
  text-align: center;
  font-size: 29px;
  color: var(--main-color);
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 6px;
  margin-bottom: 20px;
  letter-spacing: 3px;
}
.footerform h3 span {
  font-weight: 800;
}
.footerform input, .footerform textarea {
  border: 1px solid #ddd;
  box-shadow: none;
  outline: none;
}

.footerform  input[type="submit"] {
  background: var(--main-color);
  padding: 8px 20px;
  color: #fff;
  font-size: 15px;
  border-radius: 5px;
  margin: auto;
  display: block;
  margin-top: 30px;
  letter-spacing: 5px;
}
p.error {
  color: red;
  font-size: 13px;
  margin-bottom: 0;
} 

p.success-message {
    color: green;
    text-align: center;
}


.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9
}

.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden
}

.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid #fff
}

.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%
}

.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center
}

.magnify-footer .magnify-toolbar {
  display: inline-block
}

.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #026a90;
}

.magnify-button:hover {
  color: #fff;
  background-color: #f02da1
}

.magnify-button-close:hover {
  background-color: #f02da1
}

.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #333
}

.magnify-loader::before {
  content: '';
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden
}

@-webkit-keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

@keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}
.inner-section.contact_bg{
  background: url(https://webapi.entab.info/api/image/SGSM/public/Images/contact-bg.jpg);
  background-size: 100% 100%;
  position: relative;
}
.inner-section.contact_bg > div{
  position: relative;
  z-index: 9;
}
.inner-section.contact_bg:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #ffffff94;
  position: absolute;
  left: 0;
  top: 0;
}
.tabSelect > div {
  width:19%;
  text-align: center;
  background: #fff0f9;
  padding: 17px 5px;
  margin-bottom: 20px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  box-shadow: 0px 20px 7px -15px #ddd;
  border: 1px solid #ffd0ec;
  color: #000;
  cursor: pointer;
}

.tabSelect {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: space-between;
}

.tabSelect > div.active {
  background: #026a90;
  color: #fff;
  border: 1px solid #026a90;
  box-shadow: 0px 20px 7px -15px #a5a5a5;
}

/* .yearSelection {
  margin-left: auto;
  display: block;
  width: 180px;
  max-width: 100%;
  margin-top: -20px;
  display: flex;
  align-items: start;
  font-size: 21px;
  font-weight: 600;
} */

.yearSelection select {
  width: 100%;
  margin-bottom: 20px;
  padding: 0 0 10px;
  border: 0;
  border-bottom: 1px solid;
  font-size: 20px;
  margin-left: 10px;
  outline: none;
}
.virtual_blk .gif_view {
  display: none;
}

.virtual_blk:hover .img_view {
  display: none;
}

.virtual_blk:hover .gif_view {
  display: block;
}

.virtual_blk img {
  margin: auto;
  display: block;
}
.wallk_through h3, .\33 60_view h3 {
  text-align: center;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 50px;
}
 
.virtual_blk {
  text-align: center;
  background: #fff;
  box-shadow: 0px 0px 11px #dfdfdf;
  margin-top: 20px;
  padding: 20px;
  border-radius: 20px;
}

.virtual_blk h3 {
  font-size: 18px;
  color: #026a90;
}
.row.strip_view { 
  padding: 9px 0;
  margin-top: 20px;
  border-radius: 9px;
  box-shadow: 0px 20px 20px -24px #ddd;
}

.row.strip_view img {
  border: 8px solid #fff;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 7px;
}

.strip_view h3 {
  color: var(--main-color);
}
.block_shadow h3 {
  color: var(--main-color);
}

.block_shadow {
  border: 2px solid #dddddd03;
  padding: 0 20px 10px;
  margin-bottom: 23px;
  border-radius: 5px;
  background: #f7f7f7;
  border-left: 3px solid #026a90;
}
@media(max-width: 1600px) {
  .owl-item.active+.owl-item .topper-img img {
    height: 100px;
  }

  .topper-img img {
    height: 132px;
  }

  .abt-cont-blk {
    width: 90%;
  }

  .message-desk .navigation-thumbs {
    width: 72%;
  }

  .life-our-school>div {
    width: 84%;
  }

  p {
    font-size: 14px;
  }

  .life-our-school {
    padding: 80px 0px;
  }

  h2.title_main span {
    font-size: 7rem;
  }

  h2.title_main {
    font-size: 36px;
  }

  .content h1 {
    font-weight: 800;
    font-size: 26px;
    margin: 10px 0px;
  }

  .content p {
    width: 470px;
    font-size: 13px;
    padding: 27px 34px;
    line-height: 23px;
  }



  .content i {
    font-size: 15px;
  }

  .imp-link p,
  .header p,
  .header .admission-btn button {
    font-size: 13px;
  }

  .topper .col-md-3 img {
    width: 100%;
  }


  .owl-item.active+.owl-item .topper-img {
    width: 65%;
  }

  .ofc-link li span {
    min-width: 90px;
  }

  .ofc-link {
    margin-top: 0px;
  }

  .topper-img {
    width: 81%;
  }

  .tpr-block .d-flex .button button {
    font-size: 13px;
  }

  .topper-cont p {
    font-size: 14px;
  }

  .abt-cont-blk p {
    font-size: 16px;
  }
}

@media(max-width: 1400px) {
  .owl-item.active+.owl-item .topper-img img {
    height: 100px;
  }

  .headerFest {
    top: -35px;
  }

  .slider-main .content h1 {
    font-size: 43px;
  }

  .slider-main .item img,
  video#videoPlayer {
    height: 75vh;
  }

  .birthday-block:before {
    left: -67px;
    top: -60px;
  }

  .cont-dtl h3 {
    font-size: 18px;
  }

  footer iframe {
    height: 117px;
  }

  .socialMedia p {
    letter-spacing: 0px;
  }

  .life-our-school .content-blk h3 {
    font-size: 24px;
    line-height: 31px;
  }

  .message-desk .thumbnails img {
    width: 123px;
  }

  .journey img.right-img {
    width: 43%;
  }

  .journey p {
    font-size: 15px;
  }

  .footer {
    width: 96%;
  }

  .topper {
    background-size: 100% 100%;
  }

  .evt-block {
    width: 90%;
    margin-left: auto;
  }

  .header .admission-btn button {
    padding: 11px 28px;
  }

  .about>div {
    width: 83%;
  }

  .academic-cal {
    height: 393px;
  }

  .message-desk>div,
  .journey>div,
  .news-events>div {
    width: 90%;
  }

  .message-desk .navigation-thumbs .owl-nav.disabled {
    right: -130px;
  }

}

@media(max-width: 1200px) {
  .footer {
    flex-direction: column;
  }

  .widget {
    width: 100%;
  }

  .owl-item.active+.owl-item .topper-img {
    width: 80%;
  }

  .topper-img {
    width: 100%;
  }

  .slider-main .item img,
  video#videoPlayer {
    height: 70vh;
  }

  .birthday-block:before {
    left: -47px;
    top: -40px;
  }

  .tpr-block.animateMe h3 {
    padding-left: 35px;
  }

  .topper-theme .item {
    height: 230px;
  }

  .abt-cont-blk {
    width: 100%;
  }

  .abt-cont-blk h2.title_main {
    margin-bottom: 30px !important;
    font-size: 30px;
  }

  .life-our-school .content-blk h3 {
    font-size: 19px;
  }

  .message-desk .thumbnail-carousel-container {
    width: 90%;
  }

  .message-desk .thumbnails img {
    width: 110px;
  }

  .message-desk .thumbnails img.active {
    width: 190px;
  }

  .message-desk .thumbnail-carousel-container .thumbnails {
    min-height: 240px;
  }

  .life-our-school .content-blk a {
    font-size: 13px;
  }

  .topper-theme .owl-nav.disabled button,
  .gallery .owl-nav.disabled button {
    width: 44px;
    font-size: 26px !important;
  }

  .news-event-filter {
    top: 30%;
  }

  .quick-link:after {
    display: none;
  }

  .topper {
    color: #fff;
    padding: 80px 40px;
    background-size: auto 100%;
  }

  .header-contact.desk-only {
    display: none;
  }

  .admission-btn {
    order: 2;
  }

  .content h1 {
    font-size: 24px;
  }

  .content p {
    width: 400px;
    font-size: 12px;
    line-height: 19px;
  }

  .menu-btn {
    order: 2;
  }
}

@media(max-width: 991px) {
  .newsletter .card img {
    height: auto;
  }
  .contact-blk > div { 
    width: 100%;
}
.tabSelect > div {
  width: 49%;}

  h3.inner-main-title:before {
    height: 96%;
    right: 4px;
    top: 1px;
    background: #026a90;
    z-index: -1;
  }

  .topper-img img {
    height: 120px;
  }

  .school_song {
    width: 90%;
  }

  .owl-item.active+.owl-item .topper-img img {
    height: 90px;
  }

  .slider-main .item img,
  video#videoPlayer {
    height: 60vh;
  }

  /* .owl-item.active+.owl-item .topper-img img {
  height: 60px;
}
.topper-img img {
  height: 88px;
  object-fit: contain;
} */
  .birthday-block:before {
    left: -77px;
    width: 250px;
    top: -80px;
  }

  .about {
    background-size: auto 100%;
    background-position: center;
  }

  .birthday-block marquee {
    min-height: 440px;
  }

  .tpr-block.animateMe h3 {
    padding-left: 10px;
  }

  .slider-main {
    min-height: auto !important
  }

  .message-title {
    padding: 30px 40px;
    width: max-content;
  }

  .message-desk {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .topper-theme .owl-nav.disabled {
    top: -58px;
    right: 0;
    left: unset;
  }

  .message-title {
    margin-bottom: 180px;
  }

  .message-desk .thumbnail-carousel-container {
    padding: 10px 10px 30px;
    width: 100%;
  }

  .topper .col-md-3 img {
    margin-top: 20px;
  }

  footer {
    background-size: auto 100%;
  }

  .life-our-school img,
  .life-our-school .content-blk,
  .virtual {
    height: 240px
  }

  .message-slider {
    width: 100%;
    margin-top: 20px
  }

  .message-desk .row {
    display: unset;
    align-items: center;
  }

  .message-desk .navigation-thumbs {
    margin: auto;
  }

  .message-desk .navigation-thumbs .owl-item.active:nth-child(2),
  .message-desk .navigation-thumbs .owl-item.active {
    transform: unset;
  }

  .message-desk .navigation-thumbs .owl-item.active .message-des {
    display: block
  }

  .message-slider .owl-carousel .owl-item img {
    display: block;
    width: 30%;
    margin: auto;
  }

  .topper .col-md-3 img {
    width: 30%;
    margin: auto;
    display: block;
  }

  .topper-theme {
    padding-left: 0;
  }

  .topper .container-fluid {
    background: #000000a3;
  }

  .ofc-link li span {
    min-width: 150px;
  }

  img.img-fluid.principalMessage {
    margin-bottom: 30px;
  }
}

@media(max-width: 767px) {
  .educationPartner .d-flex {
    flex-direction: column;
}
  h3.inner-main-title span.light-bg:before {
    display: none;
  }

  h3.inner-main-title span.light-bg {
    background: transparent !important;
  }

  h3.inner-main-title span {
    color: #026a90;
    background: transparent;
  }

  h3.inner-main-title:before {
    background: transparent !important;
  }

  h3.inner-main-title {
    margin: 0;
  }

  .inr-event-blk {
    flex-direction: column;
  }

  .school_song {
    padding: 40px 50px;
  }

  h3.inner-main-title:before {
    content: "";
    width: 98%;
    height: 97%;
    position: absolute;
    right: 3%;
    top: 1px;
    background: #026a90;
    z-index: -1;
  }

  .inr-event-blk .inr-event-img {
    width: 100%;
  }

  .inr-event-img img {
    margin: auto;
    display: block;
  }

  .slider-main {
    min-height: 100vh;
    position: relative;
    margin-top: 0px;
  }

  .timeline>.d-flex {
    flex-direction: column;
  }

  .timeline>.d-flex>div {
    width: 100% !important;
    text-align: left;
    padding: 0;
  }

  .timeline>.d-flex>div.content {
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
  }

  .timeline .content1 p {
    font-size: 15px;
    width: 100%;
    padding: 0;
  }

  .timeline:before {
    display: none;
  }

  .timeline>.d-flex:before,
  .timeline>.d-flex:after {
    display: none;
  }

  .topper-img img {
    height: 90px;
  }

  .d-flex.rules-reg {
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-left: 0;
    border-bottom: 5px solid var(--main-color);
  }

  .d-flex.rules-reg img {
    margin: 0;
    margin-bottom: 19px;
  }

  .breadcrumb h2 {
    font-size: 36px;
  }

  .breadcrumb ul li {
    font-size: 13px;
  }

  .breadcrumb {
    min-height: 180px;
  }

  .slider-main .item img,
  video#videoPlayer {
    height: 40vh;
    object-fit: cover;
  }

  .life-our-school {
    background-size: auto 100%;
  }

  .birthday-block:before {
    left: -40px;
    width: 200px;
    top: -40px;
  }

  img.gift-right {
    display: none;
  }

  .journey .tabs {
    padding-left: 40px;
  }

  .title.message-box {
    margin-bottom: 0;
  }

  .evt-blk {
    margin-left: 0;
  }

  .birthday-block marquee {
    min-height: 290px;
  }

  .life-our-school .content-blk:before {
    display: none;
  }

  .life-our-school h2.title_main {
    margin-bottom: 50px !important;
    font-size: 25px;
  }

  .journey {
    margin-bottom: 0;
  }

  h2.title_main {
    margin-bottom: 0 !important;
    font-size: 25px;
  }

  h2.title_main.m-auto small {
    font-size: 20px;
  }

  .message-desk .thumbnails img.active {
    width: 150px;
  }

  .evt-block .button {
    padding: 7px 22px !important;
  }

  .topper {
    color: #fff;
    padding: 40px 20px;
    background-size: auto 100%;
  }

  .tpr-block .d-flex .owl-carousel {
    width: 78%;
  }

  .topper-theme .owl-nav.disabled {
    top: -58px;

    left: unset;
  }

  .message-desk .thumbnails img {
    width: 80px;
  }

  .message-desk .thumbnail-carousel-container .thumbnails {
    margin-top: -110px;
    min-height: 200px;
  }

  .message-desk .thumbnails img {
    display: none;
  }

  .message-desk .thumbnails img.active {
    width: 170px;
    display: block;
  }

  .news-event-filter>div {
    background: #fff;
    color: #000;
    margin: 10px 0;
    padding: 8px 10px;
    font-size: 14px;
    border-radius: 0;
    text-align: center;
    min-width: 80px;
    text-transform: uppercase;
  }

  footer .logo {
    width: 49% !important;
    margin: auto;
    display: block;
  }

  .topper-img,
  .owl-item.active+.owl-item .topper-img {
    width: 78%;
  }

  .gallery .owl-nav.disabled {
    bottom: 0;
    left: unset;
    right: 0;
  }

  .topper-theme .owl-nav.disabled button,
  .gallery .owl-nav.disabled button {
    width: 23px;
  }

  .topper-theme .owl-nav.disabled,
  .gallery .owl-nav.disabled {
    bottom: 0;
  }

  .message-desk .navigation-thumbs .owl-nav.disabled {
    bottom: 0;
    right: -22%;
    top: 0;
    transform: translate(0, 0%);
  }

  .message-desk .navigation-thumbs .owl-nav.disabled button {
    width: 22px;
    font-size: 26px;
    margin: 2px 4px;
  }

  .evt-block {
    width: 80%;
    margin-left: auto;
  }

  .about {
    padding: 80px 0 0;
  }

  .message-slider {
    background: #dfe7e88a;
    padding: 20px 20px;
  }

  .message-desk>div,
  .news-events>div {
    width: 100%;
  }

  .life-our-school img,
  .life-our-school .content-blk,
  .virtual {
    height: auto
  }

  p {
    font-size: 14px;
  }

  .highlights .col-lg-2 {
    display: none;
  }

  .slider-main .content {
    display: none;
  }

  .header .d-flex {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .imp-link {
    width: auto !important;
  }

  .header .d-flex>div {
    width: auto;
  }

  .logo {
    width: 100% !important;
  }

  .logo img {
    width: 60%;
    margin: auto;
    display: block;
  }

  .imp-link p a {
    margin: 0 0px;
  }

  .imp-link p,
  .header p,
  .header .admission-btn button {
    font-size: 13px;
    padding: 4px 4px;
  }

  .header {
    position: static;
    padding: 5px 16px 0 5px;
  }

  .menu-toggle img {
    width: 24px;
  }


  .gallery {
    padding: 50px 0 100px;
  }

  .gallery:before {
    width: 39%;
  }

  .life-our-school .content-blk {
    box-shadow: none;
  }

  .gallery:after {
    width: 59%;
  }

  .flex-list li {
    width: 50%;
  }

  .inner-section p,
  .inner-section p li {
    font-size: 16px;
  }

  .col-md-12.note {
    width: 96%;
    margin: auto;
  }
  .yearSelection{
    margin-top: 0;
  }
}

@media(max-width: 600px) {
  .tabSelect > div {
    width: 100%;}
  .topper-img img {
    height: 190px;
  }

  .topper-img {
    /* padding: 33px 73px 66px;  */
    padding: 33px 53px 56px;

  }

  .topper-img {
    width: 70% !important;
  }

  .topper-img,
  .owl-item.active+.owl-item .topper-img {
    width: 52%;
    margin: auto;
  }

  .flex-list li {
    width: 100%;
  }

  .box-light {
    flex-direction: column;
  }

  h3.inner-main-title {
    font-size: 27px;
  }

  .box-light .heading {
    width: 100%;
    flex-direction: row;
  }

  .box-light .content {
    width: 100%;
  }

  .box-light .heading img {
    margin-right: 12px;
  }

  .login-link img {
    width: 70px;
    transform: rotate(90deg);
    margin-top: 12px;
  }

  .birthday-content {
    width: 100%;
  }

  .footer .logo {
    margin-bottom: 30px;
    width: 300px !important;
  }

  .tpr-block .d-flex {
    flex-direction: column;
  }

  .tpr-block .d-flex .owl-carousel {
    width: 100%;
  }

  .ofc-link li span {
    min-width: 110px;
    max-width: unset;
  }

  .life-our-school .content-blk a {
    font-size: 12px;
    padding: 5px 10px;
    letter-spacing: 0px;
  }

  .right-img-blk {
    margin-bottom: 20px;
  }

  .journey .tabs {
    padding-left: 20px;
  }

  .about>div {
    width: 93%;
  }

  .journey .tab-content {
    padding: 0 10px;
    transition: 0.5s;
  }

  .journey .tabs:before {
    width: 267%;
  }

  /* .topper-img, .owl-item.active+.owl-item .topper-img {
    width: 40%;
    margin: auto;
} */
  .message-slider .owl-carousel .owl-item img {
    width: 90%;
  }

  footer .logo {
    width: 80% !important;
  }

  .news-events {
    padding: 20px 0;
  }

  .journey img.right-img {
    width: 100%;
    float: unset;
    margin-left: 0;
  }

  .message-desk {
    background-size: auto 100%;
    padding: 60px 0;
  }

  .journey .tabs {
    width: 100%;
    overflow-x: scroll;
    overflow-y: visible;
    padding-bottom: 20px;
    min-height: 112px;
  }

  .topper .col-md-3 img {
    width: 80%;
    margin: auto;
    display: block;
    margin-top: 40px;
  }

  .journey .tabs:before {
    bottom: 20px;
  }

  .imp-link {
    width: 100% !important;
    text-align: center;
  }

  .logo img {
    width: 75%;
  }

  .header {
    padding: 6px 21px 0 19px;
  }

  .journey>div {
    padding: 30px 0;
  }

  .gal-img {
    width: 100%;
  }

  .birthday-block marquee {
    min-height: 160px;
  }

  .birthday {
    width: 100%;
  }
}

@media(max-width: 450px) {

  .slider-main .item img,
  video#videoPlayer {
    height: 20vh;
    object-fit: cover;
  }

  .topper-img img {
    height: 140px;
  }
}

@media(max-width: 400px) {
  .topper-img {
    width: 82% !important;
  }
}