:root {
  --main-color: #29358d;
  --light-gradient: linear-gradient(to right, #6370cf, #8f9dff,  #6370cf, #8f9dff)
}
body,
html {
  margin: 0;
  padding: 0;
}

a {
  color: inherit !important;
  text-decoration: none !important;
  color: inherit !important;
}

.menu {
  width: 100%;
  float: left;
  height: 0;
  overflow-y: scroll;
}

.menu-toggle {
  display: block;
  z-index: 9999;
  border-radius: 10px;
  margin-left: auto;
  padding: 10px;
  border: 0px solid;
  transition: 0.5s;
  background: #0000; 
}  

.submenu li:last-child {
  border-right: 0;
}

.submenu li {
  padding: 0 10px;
  position: relative;
  width: max-content;
  color: var(--main-color);  
  border-right: 1px solid;
  font-size: 23px;
  cursor: pointer;
  width: 23%;
  padding: 20px 0px;
  border: 0;
  margin-bottom: 11px;
  font-weight: 600; 
  text-transform: capitalize;
  font-size: 15px;
  border-right: 0;  
}
.sub-submenu li {
  width: 100%;
  color: #333333;
  font-weight: 400;
  padding: 2px 0px;
  position: relative;
}
.submenu > li:before {
  content: "";
  width: 10%;
  height: 2px;
  background: var(--dark-gradient);
  position: absolute;
  top: 50px;
  border-radius: 5px;
  opacity: 0;
  transition: 0.5s;
}
.submenu > li:hover:before{
  width: 50%;
  opacity: 1;
}
.submenu li:last-child {
  border-bottom: none;
  margin-bottom: 0;
} 

.sub-submenu { 
    position: absolute;
    top: 100%;
    left: 0%; 
    padding-left: 0;
    list-style-type: none;
    padding: 9px 5px; 
    border-radius: 5px;
}
 

.sub-submenu li:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.submenu li:hover .sub-submenu {
  display: block;
}

.ph-only {
  display: none
} 

.submenu li i.bi.bi-chevron-down {
  margin-left: 5px;
  font-size: 11px;
}
 



@media(max-width: 1200px) {

  .sub-submenu {
    padding: 9px 0px;
  }

  .submenu li { 
    margin: 0 0px;
    font-size: 14px;
  }

  .sub-submenu li {
    color: #000 !important;
  }
}

.submenu li i.bi.bi-chevron-down {
  float: right;
}

.sub-submenu {
  position: static;
  margin-top: 16px;
}
 

.menu-toggle {
  display: block;
  z-index: 9999; 
  border-radius: 10px; 
  margin-left: auto;
} 
.menu ul.submenu {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
}
.menu .logo {
  width: 400px !important;
  margin: auto;
  margin-bottom: 30px;
}

.submenu li i {
  font-size: 22px;
}
.back-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: #00000073;
}
.crossMe {
  position: fixed;
  right: 0;
  top: 0;
  /* background: #fff; */
  z-index: 999;
  color: #000;
  font-size: 26px;
  padding: 3px 10px;
  font-weight: 600;
cursor: pointer;
} 
.menu { 
  list-style-type: none;
  padding: 0;
  position: fixed;
  bottom: 0;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  z-index: 1;
  border: none;
  flex-direction: column;
  padding: 20px 20px;
  width: 100%; 
  box-shadow: -1px 1px 5px 0px #000000a1;
  z-index: 99;
  height: 100%;
}
.sub-submenu li:hover {
  color: var(--main-color);
}
@media (max-width: 991px) {
  .menu ul.submenu { 
    width: 90%; 
}
.submenu >li{
  width: 32%
}
}

@media(max-width:600px) {
  .submenu >li {
    width: 50%;
}
.menu .logo {
  width: 100% !important;}

}
@media(max-width:400px) {
  .submenu >li {
    width: 100%;
}
 

}